import cookie from '../plugins/jquery.cookie.js';
import Headroom from '../plugins/headroom.js';
import slick from '../plugins/slick.js';


jQuery(function($) {

  // Home titles
  var time = 250;
  $('.fademein').each(function(){
    var $this = $(this);
    setTimeout( function(){ $this.addClass('visible'); }, time);
    time += 250;
  });

  // Feature Popups
  $('.close-feature-popup').click(function(){
    $(this).parents('.feature-popup').removeClass('visible');
  });
  $('.image-map-toggle').click(function(e){
    e.preventDefault();
    var $offset = $(window).scrollTop() - $('.features-top').offset().top + 130;
    $($(this).attr('href')).addClass('visible').find('.popup-content-top').css('top', $offset);
  });
  $('.prev-popup-toggle').click(function(){
    var $offset = $(window).scrollTop() - $('.features-top').offset().top + 130;
    var $popup = $(this).parents('.feature-popup');
    $popup.removeClass('visible');
    if ($popup.prev('.feature-popup').length) {
      $popup.prev('.feature-popup').addClass('visible').find('.popup-content-top').css('top', $offset);
    } else {
      $('.feature-popup:last-child').addClass('visible').find('.popup-content-top').css('top', $offset);
    }
  });
  $('.next-popup-toggle').click(function(){
    var $offset = $(window).scrollTop() - $('.features-top').offset().top + 130;
    var $popup = $(this).parents('.feature-popup');
    $popup.removeClass('visible');
    if ($popup.next('.feature-popup').length) {
      $popup.next('.feature-popup').addClass('visible').find('.popup-content-top').css('top', $offset);
    } else {
      $('.feature-popup:first-child').addClass('visible').find('.popup-content-top').css('top', $offset);
    }
  });

  // Sticky Header
  var myElement = document.querySelector(".global-header");
  var headroom  = new Headroom(myElement, {
    "offset": 350,
    "tolerance": 0
  });
  headroom.init();

  // Gallery Slider
  $('.gallery-slider').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    autoplay: false,
    slidesToShow: 1,
    variableWidth: false,
    prevArrow: '<svg class="prev" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><circle cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="#F1F4F8"/><path d="M33.75 23.9678H34.725V25.9178H33.75V23.9678ZM16.8759 25.9178H15.9009V23.9678H16.8759V25.9178ZM15.0201 25L14.3307 25.6894L13.6413 25L14.3307 24.3106L15.0201 25ZM33.75 25.9178H16.8759V23.9678H33.75V25.9178ZM24.4595 16.9394L15.7096 25.6894L14.3307 24.3106L23.0806 15.5606L24.4595 16.9394ZM15.7096 24.3106L24.4595 33.0606L23.0806 34.4394L14.3307 25.6894L15.7096 24.3106Z" fill="#00335D"/></svg>',
    nextArrow: '<svg class="next" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><circle cx="25" cy="25" r="25" fill="#F1F4F8"/><path d="M16.25 23.9678H15.275V25.9178H16.25V23.9678ZM33.1241 25.9178H34.0991V23.9678H33.1241V25.9178ZM34.9799 25L35.6693 25.6894L36.3587 25L35.6693 24.3106L34.9799 25ZM16.25 25.9178H33.1241V23.9678H16.25V25.9178ZM25.5405 16.9394L34.2904 25.6894L35.6693 24.3106L26.9194 15.5606L25.5405 16.9394ZM34.2904 24.3106L25.5405 33.0606L26.9194 34.4394L35.6693 25.6894L34.2904 24.3106Z" fill="#00335D"/></svg>',
    adaptiveHeight: true
  });

  // Slick Slider
  $('.video-carousel').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    autoplay: false,
    slidesToShow: 1,
    variableWidth: false,
    prevArrow: '<svg class="prev" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><circle cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="#F1F4F8"/><path d="M33.75 23.9678H34.725V25.9178H33.75V23.9678ZM16.8759 25.9178H15.9009V23.9678H16.8759V25.9178ZM15.0201 25L14.3307 25.6894L13.6413 25L14.3307 24.3106L15.0201 25ZM33.75 25.9178H16.8759V23.9678H33.75V25.9178ZM24.4595 16.9394L15.7096 25.6894L14.3307 24.3106L23.0806 15.5606L24.4595 16.9394ZM15.7096 24.3106L24.4595 33.0606L23.0806 34.4394L14.3307 25.6894L15.7096 24.3106Z" fill="#00335D"/></svg>',
    nextArrow: '<svg class="next" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><circle cx="25" cy="25" r="25" fill="#F1F4F8"/><path d="M16.25 23.9678H15.275V25.9178H16.25V23.9678ZM33.1241 25.9178H34.0991V23.9678H33.1241V25.9178ZM34.9799 25L35.6693 25.6894L36.3587 25L35.6693 24.3106L34.9799 25ZM16.25 25.9178H33.1241V23.9678H16.25V25.9178ZM25.5405 16.9394L34.2904 25.6894L35.6693 24.3106L26.9194 15.5606L25.5405 16.9394ZM34.2904 24.3106L25.5405 33.0606L26.9194 34.4394L35.6693 25.6894L34.2904 24.3106Z" fill="#00335D"/></svg>',
    adaptiveHeight: true
  });

  // Button SVGs
  $('.btn, .arrowed, .gform_button').each(function () {
    var $text = $(this).text(),
        $lastIndex = $text.lastIndexOf(' '),
        $cutText = $text.substring(0, $lastIndex),
        $lastWord = $text.split(' ').pop();
    $(this).html($cutText + ' <span class="btn-word">' + $lastWord + '<svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" viewBox="0 0 7 9" fill="none"><path d="M7 4.49995L0 0.458618V8.54128L7 4.49995Z" fill="#00335D"/></svg><span>');
  });

  // Timeline
  if ($('section.timeline').length) {
    $(window).scroll(function(){
      if ($('section.timeline .inner').offset().top < $(window).scrollTop() + $(window).height() / 2 - 100 && $('section.timeline .inner').offset().top + $('section.timeline .inner').outerHeight() > $(window).scrollTop() + $(window).height() - $(window).height() / 2 + 100) {
        $('.arrows-wrapper').addClass('stuck');
      } else {
        $('.arrows-wrapper').removeClass('stuck');
      }
    });
  }
  $('#timeline-arrow-prev').click(function(){
    $('.timeline-item').each(function(){
      if ($(this).offset().top < $(document).scrollTop()) {
        $('html, body').stop().animate({
          scrollTop: $(this).offset().top - 100
        });
      }
    });
  });
  $('#timeline-arrow-next').click(function(){
    $('.timeline-item').each(function(){
      if ($(this).offset().top > $(document).scrollTop() + 110) {
        $('html, body').stop().animate({
          scrollTop: $(this).offset().top - 100
        });
        return false;
      }
    });
  });

  // Search
  $('.search-toggle').click(function(e){
    e.preventDefault();
    $('#search-popup').toggleClass('active');
    $('#search-input').focus();
  });
  $('.close-search').click(function(){
    $('#search-popup').removeClass('active');
  });
  if ($(window).width() < 768) {
    $('#search-input').attr('placeholder', 'Keyword...');
    $('#search-submit').html('Go <svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" viewBox="0 0 7 9" fill="none"><path d="M7 4.49995L0 0.458618V8.54128L7 4.49995Z" fill="#00335D"/></svg>');
  }

  // Cookied Popup
  var $delay = 0;
  if ($('.cookied-popup').length) {
    $delay = $('.cookied-popup').data('delay');
    setTimeout(function(){
      $('.cookied-popup').addClass('visible');
    }, $delay);
  }
  $('.close-cookie-popup').on('click', function(){
    $('.cookied-popup').fadeOut();
    $.cookie('popup', $('.cookied-popup').attr('id'), { path: '/' });
  });

  // Status Bar
  $('#status-close').on('click', function(){
    $('.status-bar').css('display', 'none');
    $.cookie('status', $('.status-bar').attr('id'), { path: '/' });
  });

  // Accordions
  $('.accordion-title').click(function(){
    $(this).parents('.the-accordion').toggleClass('open');
  });

  // Anchor
  $('a').on('click', function(e){
    var $href = $(this).attr('href');
    if ($href && $href.match('^#') && !$(this).hasClass('image-map-toggle')) {
      e.preventDefault();
      $('html, body').stop().animate({
          scrollTop: $($href).offset().top
      });
    }
  });
  
  // Video popups
  $('a').on('click', function(e){
    var $target = $(this).attr('href');
    if ($target) {
      if ($target.toLowerCase().indexOf("vimeo") >= 0) {
        e.preventDefault();
        var $n = $target.lastIndexOf('/');
        var $vid = $target.substring($n + 1);
        $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
        $('.video-popup-wrapper').addClass('active');
        $('body, html').addClass('body-noscroll');
      }
      if ($target.toLowerCase().indexOf("youtu") >= 0) {
        e.preventDefault();
        var $y = $target.lastIndexOf('=');
        var $yid = $target.substring($y + 1);
        $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
        $('.video-popup-wrapper').addClass('active');
        $('body, html').addClass('body-noscroll');
      }
    }
  });
  $('.close-popup').on('click', function(){
    $('.video-popup-wrapper').removeClass('active');
    $('.popup-content').html('');
    $('body, html').removeClass('body-noscroll');
  });

  // Mobile Nav
  $('#mobile-nav-toggle').on('click', function(){
    $('html, body').stop().animate({
        scrollTop: 0
    });
    var $navheight = $('#mobile-navigation').height() + 170;
    $('body, html').toggleClass('nav-locked');
    $('#mobile-navigation-wrapper').toggleClass('active');
    $(this).toggleClass('active');
  });
  $('.mobile-navigation-wrapper .menu-item-has-children').click(function(){
    $(this).toggleClass('open');
  });

  // Resize for Match-Height and Slick-Slider glitch fix
  scroll_actions();
  $(window).bind('load', function(){
    $(window).trigger('resize');
    setTimeout(function(){ $(window).trigger('resize'); }, 500);
    scroll_actions();
  });
  $(document).ready(scroll_actions());
  $(window).scroll(function(){
    scroll_actions();
  });

  function scroll_actions() {
    console.log(1);
    $('.loading').each(function(){
      $(this).addClass('loaded');
    });
    $('.animate').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        $this.addClass('visible');
      }
    });
    $('.animate-1').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 100);
      }
    });
    $('.animate-2').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 200);
      }
    });
    $('.animate-3').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 300);
      }
    });
    $('.animate-4').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 400);
      }
    });
    $('.animate-5').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 500);
      }
    });
    $('.animate-6').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 600);
      }
    });
    $('.animate-7').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 700);
      }
    });
    $('.animate-8').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 800);
      }
    });
    $('.animate-9').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 900);
      }
    });
    $('.animate-10').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1000);
      }
    });
    $('.animate-11').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1100);
      }
    });
    $('.animate-12').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1200);
      }
    });
    $('.animate-13').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1300);
      }
    });
    $('.animate-14').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1400);
      }
    });
    $('.animate-15').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1500);
      }
    });
    $('.animate-16').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1600);
      }
    });
    $('.animate-17').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1700);
      }
    });
    $('.animate-18').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1800);
      }
    });
    $('.animate-19').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1900);
      }
    });
    $('.animate-20').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2000);
      }
    });
    $('.animate-21').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2100);
      }
    });
    $('.animate-22').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2200);
      }
    });
    $('.animate-23').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2300);
      }
    });
    $('.animate-24').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2400);
      }
    });
    $('.animate-25').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2500);
      }
    });
  }

});